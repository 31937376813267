.publications {
  max-width: 900px;
  margin: 0 auto;
  padding: 2em 0;
}

.publications__list {
  list-style-type: none;
  padding: 0;
}

.publication__year-group {
  margin-bottom: 2em;
}

.publication__year-heading {
  font-size: 1.5em;
  font-weight: bold;
  color: var(--clr-primary);
  margin-bottom: 0.5em;
  border-bottom: 2px solid var(--clr-primary);
  padding-bottom: 5px;
}

.publication__item {
  margin-bottom: 1em;
  line-height: 1.6;
}

.publication__title {
  font-weight: bold;
  color: var(--clr-primary);
  text-decoration: none;
}

.publication__title:hover {
  text-decoration: underline;
}

.publication__authors,
.publication__conference {
  font-style: italic;
  color: var(--clr-fg-alt);
}

@media (max-width: 600px) {
  .publication__item {
    font-size: 0.95em;
  }

  .publication__year-heading {
    font-size: 1.2em;
  }
}
